import { Injectable, ElementRef } from "@angular/core";
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Toast } from '@common/core/ui/toast.service';
import {EditorState} from 'app/image-editor/state/editor-state';
import {OverlayPanel} from 'common/core/ui/overlay-panel/overlay-panel.service';
import {Store} from '@ngxs/store';
import {OverlayPanelRef} from 'common/core/ui/overlay-panel/overlay-panel-ref';
import {CanvasStateService} from 'app/image-editor/canvas/canvas-state.service';
import { RemoveBackgroundToolComponent } from "./remove-background-tool.component";
import { AmplitudeService } from "wondercard/analytics/amplutide.service";


@Injectable()
export class RemoveBackgroundToolService {
  private removeBackgroundPanelRef: OverlayPanelRef<RemoveBackgroundToolComponent>;

    constructor(
        private http: HttpClient,
        protected toast: Toast,
        private overlayPanel: OverlayPanel,
        private store: Store,
        private canvasState: CanvasStateService,
        private amplitude: AmplitudeService,
    ) {}

    public open() {
      if ( ! this.shouldShowOpenImageDialog()) return;

      const positionStrategy = this.overlayPanel.overlay.position()
          .flexibleConnectedTo(new ElementRef(this.canvasState.wrapperEl))
          .withPositions([{overlayX: 'center', overlayY: 'center', originX: 'center', originY: 'center'}]);

      this.removeBackgroundPanelRef = this.overlayPanel.open(
        RemoveBackgroundToolComponent,
          {
              hasBackdrop: true,
              closeOnBackdropClick: false,
              positionStrategy: positionStrategy,
              panelClass: ['floating-panel', 'remove-background-panel']
          }
      );

      this.amplitude.track('Opened remove background dialog');

      this.removeBackgroundPanelRef.afterClosed().subscribe(() => {
        this.removeBackgroundPanelRef = null;
      });
    }

  /**
   * Check if "open image" dialog window should be shown.
   */
  private shouldShowOpenImageDialog() {
      return this.store.selectSnapshot(EditorState.visible) &&
          !this.removeBackgroundPanelRef;
  }

    public executeRemoveBackground(image_data_uri): Promise<any> {
      const url = environment.BACKEND_BASE_URL + '/api/v1/clippingmagic/remove_background/';
      return new Promise(resolve => {
          this.http.post(url, {'data_uri_origin': image_data_uri, 'test_mode': environment.CLIPPINGMAGIC_TEST_MODE})
          .pipe(
              catchError((error: HttpErrorResponse) => {
                  let errorMessage = '';
                  
                  if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `Произошла ошибка при обработке фона: ${error.error.message}`;
                  } else {
                    // server-side error
                    errorMessage = `Произошла ошибка при обработке фона\nКод ошибки: ${error.status}\n${error.message}`;
                  }
                  
                  this.toast.open(errorMessage, {duration: 15000});
                  
                  return throwError(errorMessage);
                })
            )
          .toPromise()
          .then(response_data => {
            this.amplitude.track('Removed background');
            
            resolve(response_data['image']);
          })
      });
    }
}
