<div class="transform-container">
    <div class="preset-buttons">
        <div class="buttons-container">
            <button type="button" class="rotate-button button-with-image small" (click)="rotateLeft()">
                <mat-icon svgIcon="rotate-left"></mat-icon>
            </button>

            <button type="button" class="rotate-button button-with-image small" (click)="rotateRight()">
                <mat-icon svgIcon="rotate-right"></mat-icon>
            </button>
        </div>

        <div class="buttons-container">
            <button type="button" class="rotate-button button-with-image small" (click)="flipVertical()">
                <mat-icon svgIcon="flip-vertical-custom"></mat-icon>
            </button>

            <button type="button" class="rotate-button button-with-image small" (click)="flipHorizontal()">
                <mat-icon svgIcon="flip-horizontal-custom"></mat-icon>
            </button>
        </div>
    </div>

    <div class="angle-slider-container" #angleSliderContainer>
        <svg #angleSlider class="angle-slider" [attr.width]="numberOfDots.length * 10" height="40px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
            <circle [attr.cx]="2 + (index * 10)" cy="20" [attr.r]="!(index % 5) ? 2 : 0.75" *ngFor="let num of numberOfDots; index as index"></circle>
        </svg>
        <div class="current-angle">{{transformTool.currentFreeAngle$ | async}}°</div>
    </div>
</div>
