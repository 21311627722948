import { environment } from 'environments/environment';
import { Injectable } from "@angular/core";
import * as amplitude from 'amplitude-js';


@Injectable({
    providedIn: 'root'
})
export class AmplitudeService {
    constructor() {
        amplitude.getInstance().init(environment.AMPLUTUDE_API_KEY);
    }

    public track(event: string, properties: object = {}): void {
        amplitude.getInstance().logEvent(event, properties);
    }
}
