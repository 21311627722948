<button type="button" mat-icon-button (click)="activeObject.duplicate()" [matTooltip]="'Дублировать' | trans">
    <mat-icon svgIcon="duplicate-filled"></mat-icon>
</button>

<button type="button" mat-icon-button (click)="activeObject.flipHorizontal()" [matTooltip]="'Отразить по горизонтали' | trans">
    <mat-icon svgIcon="flip-filled" class="front-sorting-filled-icon"></mat-icon>
</button>

<button type="button" mat-icon-button (click)="bringActiveObjectToFront()" [matTooltip]="'Перемистить над всеми слоями' | trans">
    <mat-icon svgIcon="front-sorting-filled" class="front-sorting-filled-icon"></mat-icon>
</button>

<button type="button" mat-icon-button (click)="deleteObject()" [matTooltip]="'Удалить' | trans">
    <mat-icon svgIcon="remove-filled"></mat-icon>
</button>

<button type="button" mat-icon-button (click)="toMainImage()" [matTooltip]="'Сделать главным изображением' | trans">
    <mat-icon svgIcon="star"></mat-icon>
</button>
