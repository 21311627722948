import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import {matDialogAnimations} from '@angular/material/dialog';
import {OverlayPanelRef} from 'common/core/ui/overlay-panel/overlay-panel-ref';
import { RemoveBackgroundToolService } from 'app/image-editor/tools/remove-background/remove-background-tool.service';
import {CanvasService} from 'app/image-editor/canvas/canvas.service';
import {ObjectControlsService} from 'app/image-editor-ui/object-controls/object-controls.service';
import {ActiveObjectService} from 'app/image-editor/canvas/active-object/active-object.service';
import {CanvasStateService} from 'app/image-editor/canvas/canvas-state.service';
import {randomString} from '@common/core/utils/random-string';

import {Image} from 'fabric/fabric-impl';

@Component({
    selector: 'remove-background-tool',
    templateUrl: './remove-background-tool.component.html',
    styleUrls: ['./remove-background-tool.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[@dialogContainer]': `'enter'`,
        '[class.floating-panel]': 'true',
    },
    animations: [
        matDialogAnimations.dialogContainer,
    ]
})
export class RemoveBackgroundToolComponent {
    public loaderText: string = '...';
    public showLoader: boolean = false;
    

    constructor(
        public panelRef: OverlayPanelRef<RemoveBackgroundToolComponent>,
        public removeBackgroundTool: RemoveBackgroundToolService,
        protected canvas: CanvasService,
        public activeObject: ActiveObjectService,
        private objectControls: ObjectControlsService,
        private canvasState: CanvasStateService,
    ) {}

    public removeBgAndClose() {
        this.showLoader = true;
        this.loaderText = 'Фон удаляется...'
        
        var activeImage = this.activeObject.get() as Image;
        const activeImageSrc = activeImage.getSrc();

        this.removeBackgroundTool.executeRemoveBackground(activeImageSrc).then(result => {
            activeImage.setSrc(result, () => {
                this.showLoader = false;
                this.loaderText = '...'

                this.canvas.render();
                this.objectControls.reposition();

                this.panelRef.close();
            }, {
                crossOrigin: "anonymous",
            });
        });
    }
}
