import {fabric} from 'fabric';
import {Injectable} from '@angular/core';
import {ActiveObjectService} from '../../canvas/active-object/active-object.service';
import {gradientPresets} from '../gradient-presets';
import {CanvasService} from '../../canvas/canvas.service';
import {IGradientOptions} from 'fabric/fabric-impl';

@Injectable()
export class FillToolService {
    constructor(
        private activeObject: ActiveObjectService,
        private canvas: CanvasService
    ) {}

    public withPattern(url: string) {
        fabric.util.loadImage(url, img => {
            const pattern = new fabric.Pattern({
                source: img,
                repeat: 'repeat'
            });
            this.activeObject.setValues({fill: pattern});
        });
    }

    public withGradient(index, type: 'stroke'|'fill' = 'fill') {
        const activeObject =  this.activeObject.get();
        if ( ! activeObject) return;

        const gradientPreset = this.scaleGradientPreset(activeObject, gradientPresets[index])

        activeObject.set(type, new fabric.Gradient(gradientPreset));
        this.activeObject.propsChanged$.next();
        this.canvas.render();
    }

    public addGradient(config: IGradientOptions) {
        gradientPresets.unshift(config);
    }

    /**
     * Scales gradiet preset to fit objects with variable domensions. Gets coordinates (0, 0) as
     * top left corner of SVG object and (100, 100) as bootom right corner.
     * 
     * @param object Active object
     * @param preset Selected gradient preset
     * @returns Scaled gradient preset (deep copy)
     */
    protected scaleGradientPreset(object, preset) {
        const scaleFactor = 100

        const objectWidth = object.get('width');
        const objectHeight = object.get('height');

        // Deep copy they say, lol
        var gradientPreset = JSON.parse(JSON.stringify(preset));

        if (objectWidth && objectHeight) {
            gradientPreset.coords.x1 = objectWidth * gradientPreset.coords.x1 / scaleFactor;
            gradientPreset.coords.x2 = objectWidth * gradientPreset.coords.x2 / scaleFactor;
            gradientPreset.coords.y1 = objectHeight * gradientPreset.coords.y1 / scaleFactor;
            gradientPreset.coords.y2 = objectHeight * gradientPreset.coords.y2 / scaleFactor;
        }

        return gradientPreset
    }
}
