import { Injectable } from "@angular/core";
import {HttpClient} from '@angular/common/http';
import { LocalStorageService } from "app/image-editor/local-storage/local-storage.service";
import { environment as env } from '../../../../environments/environment';


@Injectable()
export class MagicToolService {
    constructor(
        private http: HttpClient,
        private localStorage: LocalStorageService,
    ) {}

    public requestMagic(presetId: string): Promise<any>  {
        interface magicRequestInterface {
            source: string;
        }

        const savedPresetContents = JSON.parse(this.localStorage.get(presetId));
        const savedSource = savedPresetContents;
        const url = `${env.BACKEND_BASE_URL}/api/v1/results/magic/`;

        return this.http.post(url, {"source": savedSource}).toPromise().then((result: magicRequestInterface) => {
            const processedSource = result.source;

            return this.localStorage.set(presetId, JSON.stringify(processedSource));
        }).catch(() => {});
    }
}
