import {environment} from '../../environments/environment'
import { OpenPanel } from './state/editor-state-actions';


export const WONDERCARD_SETTINGS = {
    crossOrigin: 'anonymous',
    baseUrl: environment.PIXIE_BASE_URL,
    saveUrl: environment.BACKEND_BASE_URL + '/api/v1/results/',
    ui: {
        defaultTheme: 'Wondercard Theme',
        themes: [
            {
                name: 'Wondercard Theme',
                colors: {
                    '--be-primary-default': '#fadc63',
                    '--be-accent-contrast': '#292929',
                    '--be-divider-default': '#e5e5e5',
                    '--be-accent-default': '#e5e5e5'
                }
            }
        ],
        showExportPanel: true,
        toolbar: {
            replaceDefaultLeftItems: true,
            replaceDefaultCenterItems: true,
            replaceDefaultRightItems: true,
        
            leftItems: [
                    {
                        type: 'button',
                        icon: 'menu',
                        text: 'Действия',
                        showInCompactMode: true,
                        dropdownItems: [
                            {
                                label: 'Добавить изображение',
                                action: 'openOverlayImage', 
                            },
                            {
                                label: 'Скачать результат',
                                action: 'exportImage',
                            },
                            {
                                label: 'Сбросить шаблон', 
                                action: function () {
                                    localStorage.removeItem(window['state_id']);
                                    window.location.reload();
                                }
                            },
                        ]
                    },
                ],
                centerItems: [
                    {
                        type: 'image',
                        src: '/assets/images/logo-edit.png',
                        showInCompactMode: true,
                    },
                ],
                rightItems: [
                    {
                        type: 'undoWidget'
                    },
                    {
                        type: 'button',
                        icon: 'history',
                        action: 'toggleHistory',
                        marginLeft: '40px',
                    },
                    {
                        type: 'button',
                        icon: 'layers',
                        action: 'toggleObjects',
                    },
                    {
                        type: 'button',
                        icon: 'close',
                        action: 'closeEditor',
                        marginLeft: '25px',
                        condition: {'ui.mode': 'overlay'},
                    },
                    {
                        type: 'button',
                        icon: 'file-download',
                        action: 'exportImage',
                        compactModeOnly: true,
                    }
                ]
        },
        colorPresets: {
            replaceDefault: true,
            items: [
                '#ffffff',
                '#000000',
                '#464966',
                '#D9DBEF',
                '#535D9A',
                '#7680C1',
                '#FD474A',
                '#F57530',
                '#FB9921',
                '#F5C551',
                '#99C975',
                '#46AF90',
                '#5D7E9C',
                '#F9ECEF',
                '#F4EEDC',
                '#E0F5ED'
            ]
        },
        nav: {
            replaceDefault: true,
            items: [
                {name: '23 февраля', icon: '/assets/images/stickers/23 февраля/11.png', action: '23 февраля'},
                {name: '8 марта', icon: '/assets/images/stickers/8 марта/6.png', action: '8 марта'},
                {name: 'Черная пятница', icon: '/assets/images/stickers/Черная пятница/first-screen-icon.png', action: 'Черная пятница'},
                {type: 'separator'},
                {name: 'text', icon: '/assets/icons/individual/text.svg', action: 'text'},
                {name: 'shapes', icon: '/assets/icons/individual/heart.svg', action: 'shapes'},
                {name: 'stickers', icon: '/assets/icons/individual/image.svg', action: 'stickers'},
                {type: 'separator'},
                {name: 'background', icon: '/assets/icons/individual/background.svg', action: 'background'},
            ]
        },
    },

    tools: {
        shapes: {
            replaceDefault: false,
        },
        stickers: {
            replaceDefault: true,
            items: [
                {
                    name: '23 февраля',
                    type: 'png',
                    items: 12,
                    thumbnailUrl: '/images/stickers/23 февраля/2.png'
                },
                {
                    name: '8 марта',
                    type: 'png',
                    items: 8,
                    thumbnailUrl: '/images/stickers/8 марта/6.png'
                },
                {
                    name: 'Черная пятница',
                    type: 'png',
                    items: 9,
                    thumbnailUrl: '/images/stickers/Черная пятница/4.png'
                },
                {
                    name: '3D значки',
                    type: 'png',
                    items: 15,
                    thumbnailUrl: '/images/stickers/3D значки/11.png'
                },
                {
                    name: 'Стрелки',
                    type: 'svg',
                    items: 124,
                    thumbnailUrl: '/images/stickers/Стрелки/1.svg'
                },
                {
                    name: 'Текстовые значки',
                    type: 'svg',
                    items: 10,
                    thumbnailUrl: '/images/stickers/Текстовые значки/0.svg'
                },
                {
                    name: 'Цветные иконки',
                    type: 'svg',
                    items: 383,
                    thumbnailUrl: '/images/stickers/Цветные иконки/0.svg'
                },
                {
                    name: 'Эмоции',
                    type: 'png',
                    items: 42,
                    thumbnailUrl: '/images/stickers/Эмоции/0.png'
                },
                {
                    name: 'Эмоджи',
                    type: 'png',
                    items: 47,
                    thumbnailUrl: '/images/stickers/Эмоджи/2.png'
                },
                {
                    name: 'Столы',
                    type: 'png',
                    items: 10,
                    thumbnailUrl: '/images/stickers/Столы/0.png'
                },
                {
                    name: 'Голограммы',
                    type: 'png',
                    items: 16,
                    thumbnailUrl: '/images/stickers/Голограммы/0.png'
                },
                {
                    name: 'Тени',
                    type: 'png',
                    items: 7,
                    thumbnailUrl: '/images/stickers/Тени/0.png'
                },
                {
                    name: 'Ноги',
                    type: 'png',
                    items: 6,
                    thumbnailUrl: '/images/stickers/Ноги/0.png'
                },
                {
                    name: 'Вкусы',
                    type: 'png',
                    items: 31,
                    thumbnailUrl: '/images/stickers/Вкусы/0.png'
                },
                {
                    name: 'Руки',
                    type: 'png',
                    items: 27,
                    thumbnailUrl: '/images/stickers/Руки/0.png'
                },
                {
                    name: 'Животные',
                    type: 'png',
                    items: 13,
                    thumbnailUrl: '/images/stickers/Животные/0.png'
                },
                {
                    name: 'Красотки',
                    type: 'png',
                    items: 8,
                    thumbnailUrl: '/images/stickers/Красотки/0.png'
                },
                {
                    name: 'Кляксы',
                    type: 'svg',
                    items: 10,
                    thumbnailUrl: '/images/stickers/Кляксы/0.svg'
                },
                {
                    name: 'Значки',
                    type: 'svg',
                    items: 50,
                    thumbnailUrl: '/images/stickers/Значки/0.svg'
                },
                {
                    name: 'Геометрия',
                    type: 'svg',
                    items: 7,
                    thumbnailUrl: '/images/stickers/Геометрия/0.svg'
                },
                {
                    name: 'Иконки',
                    type: 'svg',
                    items: 121,
                    thumbnailUrl: '/images/stickers/Иконки/103.svg'
                },
                {
                    name: 'Спортсмены',
                    type: 'png',
                    items: 11,
                    thumbnailUrl: '/images/stickers/Спортсмены/0.png'
                },
                {
                    name: 'Малыш (Круг)',
                    type: 'png',
                    items: 9,
                    thumbnailUrl: '/images/stickers/Малыш (Круг)/0.png'
                },
                {
                    name: 'Дети (обводка)',
                    type: 'png',
                    items: 13,
                    thumbnailUrl: '/images/stickers/Дети (обводка)/0.png'
                },
                {
                    name: 'Декор',
                    type: 'svg',
                    items: 10,
                    thumbnailUrl: '/images/stickers/Декор/0.svg'
                },
                {
                    name: 'Новый год',
                    type: 'png',
                    items: 15,
                    thumbnailUrl: '/images/stickers/Новый год/9.png'
                },
            ]
        },
    },
    
    blankCanvasSize: {
        width: 900, 
        height: 1200  
    },

    objectDefaults: {
        global: {
            strokeWidth: 0
        }
    },

    objectControls: {
        global: {
            unlockAspectRatio: false
        },
        basicShape: {
            unlockAspectRatio: true
        },
        sticker: {
            unlockAspectRatio: false
        },
        text: {
            unlockAspectRatio: false
        }
    },
    
    languages: {
        active: 'russian',
        custom: {
            russian: {
                "filter": "Фильтры",
                "resize": "Изменить размер",
                "crop": "Кадрировать",
                "transform": "Трансформация",
                "draw": "Рисунок",
                "drawing": "Рисунок",
                "text": "Текст",
                "shape": "Фигура",
                "shapes": "Фигуры",
                "sticker": "Объект",
                "stickers": "Объекты",
                "frame": "Рамка",
                "corners": "Закругление углов",
                "background": "Фон",
                "merge": "Объединить слои",
                "History": "История",
                "Objects": "Слои",
                "image": "Изображение",
                "mainImage": "Главное изображение",
                "Canvas Background": "Фон холста",
                "Width": "Ширина",
                "Height": "Высотка",
                "Brush Color": "Цвет кисти",
                "Brush Type": "Тип кисти",
                "Brush Size": "Размер кисти",
                "Cancel": "Отмена",
                "Close": "Назад",
                "Apply": "Применить",
                "Size": "Размер",
                "Maintain Aspect Ratio": "Сохранять пропорции",
                "Use Percentages": "Использовать проценты",
                "Radius": "Радиус",
                "Align Text": "Выровнять текст",
                "Format Text": "Форматировать текст",
                "Color Picker": "Выбрать цвет",
                "Add Text": "Добавить текст",
                "Font": "Шрифт",
                "Upload": "Загрузить",
                "Google Fonts": "Google Fonts",
                "Search...": "Поиск...",
                "Shadow": "Тень",
                "Color": "Цвет",
                "Outline": "Контур",
                "Background": "Цвет фона",
                "Texture": "Текстура",
                "Gradient": "Градиент",
                "Text Style": "Текстовый стиль",
                "Delete": "Удалить",
                "Background Color": "Цвет фона",
                "Outline Width": "Ширина котура",
                "Blur": "Размытие",
                "Offset X": "Смещение оси X",
                "Offset Y": "Смещение оси Y",
                "Open": "Открыть",
                "Save": "Скачать",
                "Zoom": "Приближение",
                "Editor": "Редактор",
                "Applied: filter": "Добавление фильтра",
                "Applied: resize": "Измнение размера",
                "Applied: crop": "Кадрирование",
                "Applied: transform": "Трансформация",
                "Added: drawing": "Добавление рисунка",
                "Added: text": "Добавление текста",
                "Added: shape": "Добавление фигуры",
                "Added: sticker": "Добавление объекта",
                "Added: frame": "Добавление рамки",
                "Objects: merged": "Объединение слоев",
                "Applied: rounded corners": "Скругление углов",
                "Changed: background": "Изменение фона",
                "Changed: background image": "Изменение фонового изображения",
                "Added: overlay image": "Добавление слоя",
                "Initial": "Начало",
                "Loaded: state": "Загрузка состояния",
                "Changed: style": "Изменение стиля",
                "Object Settings": "Редактирование объекта",
                "Replace": "Заменить",
                "Opacity": "Прозрачность",
                "File Name": "Имя файла",
                "Quality": "Качество изображения",
                "Double click to edit": "Двойной клик для редактирования"
            }
        }
    },
};
