<div *ngIf="!file && !hooray" class="header colorful">
    <button type="button" class="close-button" mat-icon-button (click)="panelRef.close()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
    <h2 trans>Шаг 1 из 2</h2>
    <div class="description">
        Давайте быстренько заполним шаблон. Для начала загрузите фото своего товара через эту форму. Она автоматически добавится в шаблон.
    </div>
</div>

<div *ngIf="file && !hooray" class="header colorful">
    <button type="button" class="close-button" mat-icon-button (click)="panelRef.close()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
    <h2 trans>Шаг 2 из 2</h2>
    <div class="description">
        Теперь уберем фон с фотографии. Нажмите кнопку и он удалится автоматически. Так вы сможете оформить яркую подложку под фото.
    </div>
</div>

<div class="header" *ngIf="hooray">
    <button type="button" class="close-button" mat-icon-button (click)="panelRef.close()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
    
    <div class="success-block">
        <div>
            <span class="hooray">🤘</span>
        </div>
        
        <div>
            Фото добавлено, но его всегда можно поменять. Теперь вы можете редактировать шаблон: двигать слои, изменять цвета и тексты.
        </div>  
    </div> 

    <div class="start-edit">
        <button type="button" class="accent" (click)="panelRef.close()">Приступаем!</button>
    </div>

</div>

<div class="body" *ngIf="!hooray">
    <div *ngIf="!file " class="file-dropoff-area">
        <ngx-file-drop dropZoneClassName="drop-zone" (onFileDrop)="dropped($event)" accept="{{allowedExtensions}}" multiple="false">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="description">Выберите или перетащите фото товара</div>
                <button type="button" (click)="openFileSelector()" trans>Выбрать файл</button>
                <div class="recomendation-formats">Можно загрузить файл в jpg, png или gif</div>
            </ng-template>
        </ngx-file-drop>

        <button type="button" class="accent">Сохранить и продолжить</button>
    </div>

    <div *ngIf="file" class="bg-remover-area">
        <div>
            <button (click)="reset()" class="secondary">← Загрузить другое фото</button>
        </div>

        <div class="uploaded-image">
            <img [src]="imageData">
        </div>

        <div>
            <button type="button" class="accent" (click)="removeBgAndHooray()">Удалить фон с фото</button>
        </div>

        <div>
            <button (click)="replaceAndHooray()" class="secondary">Продолжить без удаления фона</button>
        </div>
    </div>
</div>

<div *ngIf="showLoader" class="loader-container">
    <div class="row">
        <div class="loader-image">
            <img src="/assets/images/loader-animation.gif" alt="{{loaderText}}"/>
        </div>
        <div class="loader-text">{{loaderText}}</div>
    </div>
</div>
