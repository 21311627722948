import {ElementRef, Injectable} from '@angular/core';
import {OpenMainImagePanelComponent} from './open-main-image-panel.component';
import {OverlayPanel} from 'common/core/ui/overlay-panel/overlay-panel.service';
import {OverlayPanelRef} from 'common/core/ui/overlay-panel/overlay-panel-ref';
import {CanvasStateService} from '../../../image-editor/canvas/canvas-state.service';
import {Store} from '@ngxs/store';
import {EditorState} from '../../../image-editor/state/editor-state';
import { AmplitudeService } from 'wondercard/analytics/amplutide.service';


@Injectable({
    providedIn: 'root',
})
export class OpenMainImagePanelService {
    private mainImagePanelRef: OverlayPanelRef<OpenMainImagePanelComponent>;

    constructor (
        private canvasState: CanvasStateService,
        private store: Store,
        private overlayPanel: OverlayPanel,
        private amplitude: AmplitudeService,
    ) {}

    public open() {
        if ( ! this.shouldShowOpenImageDialog()) return;

        const positionStrategy = this.overlayPanel.overlay.position()
            .flexibleConnectedTo(new ElementRef(this.canvasState.wrapperEl))
            .withPositions([{overlayX: 'center', overlayY: 'center', originX: 'center', originY: 'center'}]);

        this.mainImagePanelRef = this.overlayPanel.open(
            OpenMainImagePanelComponent,
            {
                hasBackdrop: true,
                closeOnBackdropClick: false,
                positionStrategy: positionStrategy,
                panelClass: ['floating-panel', 'sample-image-panel']
            }
        );

        this.mainImagePanelRef.afterClosed().subscribe(() => {
            this.amplitude.track('Started editor');

            this.mainImagePanelRef = null;
        });
    }

    /**
     * Check if "open image" dialog window should be shown.
     */
     private shouldShowOpenImageDialog() {
        return this.store.selectSnapshot(EditorState.visible) &&
            !this.mainImagePanelRef;
    }
}
