<div class="inner-zone" #innerZone>
    <corner-handle position="top-left" class="handle-inside"></corner-handle>
    <corner-handle position="top-right" class="handle-inside"></corner-handle>
    <corner-handle position="bottom-left" class="handle-inside"></corner-handle>
    <corner-handle  position="bottom-right" class="handle-inside"></corner-handle>

    <div class="line ver-1" #lineVer1></div>
    <div class="line ver-2" #lineVer2></div>
    <div class="line hor-1" #lineHor1></div>
    <div class="line hor-2" #lineHor2></div>
</div>

<div class="mask top" #maskTop></div>
<div class="mask left" #maskLeft></div>
<div class="mask right" #maskRight></div>
<div class="mask bottom" #maskBottom></div>
