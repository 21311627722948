export const BrushTypes = [
    'PencilBrush',
    'SprayBrush',
    'CircleBrush',
    'DiamondBrush',
    'VLineBrush',
    'HLineBrush',
    'SquareBrush',

];

export const BrushSizes = [1, 8, 15, 20, 25];
