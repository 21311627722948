import { PromoVideoComponent } from './promo-video.component';
import { CanvasStateService } from '../../../image-editor/canvas/canvas-state.service';
import { ElementRef, Injectable } from '@angular/core';
import { OverlayPanel } from 'common/core/ui/overlay-panel/overlay-panel.service';
import { SafeResourceUrl } from '@angular/platform-browser';


@Injectable({
    providedIn: 'root',
})
export class PromoVideoOpenerService {
    constructor (
        private canvasState: CanvasStateService,
        private overlayPanel: OverlayPanel,
    ) {}

    public open(videoUrl: SafeResourceUrl) {
        const positionStrategy = this.overlayPanel.overlay.position()
            .flexibleConnectedTo(new ElementRef(this.canvasState.wrapperEl))
            .withPositions([{overlayX: 'center', overlayY: 'center', originX: 'center', originY: 'center'}]);

        this.overlayPanel.open(
            PromoVideoComponent,
            {
                closeOnBackdropClick: false,
                positionStrategy: positionStrategy,
                panelClass: ['floating-panel', 'promo-video-panel'],
                data: {videoUrl: videoUrl},
            }
        );
    }
}
