<div class="header">
    <button type="button" class="close-button" mat-icon-button (click)="panelRef.close()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
    <h2 trans>Убрать фон с фото</h2>
    <div class="description">
        «Убрать фон» — это инструмент для обтравки ваших фотографий товаров. Выберите слой со своим фото, нажмите кнопку и фон удалится автоматически. Так вы сможете оформить яркую подложку или добавить декоративные элементы. 
    </div>
</div>

<div class="body">
    <div class="row">
        <div class="illustration"><img src="/assets/images/remove-bg-illustration-1.png" alt=""/></div>
        <div class="delimiter"><img src="/assets/images/remove-bg-illustration-2.png" alt=""/></div>
        <div class="illustration"><img src="/assets/images/remove-bg-illustration-3.png" alt=""/></div>
    </div>

    <div class="row">
        <button type="button" class="accent" (click)="removeBgAndClose()">Удалить фон</button>
    </div>

    <div class="row">
        <button (click)="panelRef.close()" class="secondary">Отменить</button>
    </div>
</div>

<div *ngIf="showLoader" class="loader-container">
    <div class="row">
        <div class="loader-image">
            <img src="/assets/images/loader-animation.gif" alt="{{loaderText}}"/>
        </div>
        <div class="loader-text">{{loaderText}}</div>
    </div>
</div>