import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { matDialogAnimations } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { OVERLAY_PANEL_DATA } from '@common/core/ui/overlay-panel/overlay-panel-data';
import { OverlayPanelRef } from 'common/core/ui/overlay-panel/overlay-panel-ref';

@Component({
    selector: 'promo-video-popup',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './promo-video.component.html',
    styleUrls: ['./promo-video.component.scss'],
    host: {
        '[@dialogContainer]': `'enter'`,
        '[class.floating-panel]': 'true',
    },
    animations: [
        matDialogAnimations.dialogContainer,
    ]
})
export class PromoVideoComponent implements OnInit {
    public videoUrl: SafeResourceUrl = null;

    constructor(
        public panelRef: OverlayPanelRef<PromoVideoComponent>,
        @Inject(OVERLAY_PANEL_DATA) private data: any
    ) { }

    ngOnInit(): void {
        console.log('Promo video injected data (safe url):');
        console.log(this.data.videoUrl);

        this.videoUrl = this.data.videoUrl;
    }
}
