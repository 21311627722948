<toolbar *ngIf="!(toolbarHidden$ | async)"></toolbar>

<editor-controls *ngIf="(navPosition$ | async) === 'top'" class="position-top"></editor-controls>

<div class="canvas-wrapper" #canvasWrapper backgroundImage="images/viewport-bg.png" [fileDropzone]="dropzoneConfig" [disableDropzoneClick]="true" (filesDropped)="onFileDropped($event)">
    <div class="canvas-mask-wrapper" #canvasMaskWrapper>
        <div class="bg-wrapper" backgroundImage="images/canvas-bg.png" [@fadeIn]="loadState.canvasVisible">
            <canvas id="pixie-canvas"></canvas>
        </div>
        <object-controls [@fadeIn]="loadState.canvasVisible"></object-controls>
        <cropzone *ngIf="cropzone.visible$ | async"></cropzone>
    </div>
    <div class="image-loading-message" [@imageLoadingAnimation] *ngIf="loadState.messageVisible"><img src="/assets/images/loader-animation.gif" alt="{{loadState.message}}..."/></div>
</div>

<editor-controls *ngIf="(navPosition$ | async) === 'bottom'" class="position-bottom"></editor-controls>
