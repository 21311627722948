import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { RemoveBackgroundToolService } from 'app/image-editor/tools/remove-background/remove-background-tool.service';
import {OverlayPanelRef} from 'common/core/ui/overlay-panel/overlay-panel-ref';
import {BehaviorSubject} from 'rxjs';
import {ImportToolService} from '../../../image-editor/tools/import/import-tool.service';
import {CanvasService} from '../../../image-editor/canvas/canvas.service';
import {fabric} from 'fabric';
import {matDialogAnimations} from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemEntry } from 'ngx-file-drop';
import {UploadedFile} from '@common/uploads/uploaded-file';
import {CanvasStateService} from 'app/image-editor/canvas/canvas-state.service';
import { ReplaceToolService } from 'app/image-editor/tools/replace/replace-tool.service';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';

interface AssetUploadPayload {
    image: string;
    data_uri: string;
    size?: number;
}

@Component({
    selector: 'open-main-image-panel',
    templateUrl: './open-main-image-panel.component.html',
    styleUrls: ['./open-main-image-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[@dialogContainer]': `'enter'`,
        '[class.floating-panel]': 'true',
    },
    animations: [
        matDialogAnimations.dialogContainer,
    ]
})
export class OpenMainImagePanelComponent {
    public newCanvasFormVisible$ = new BehaviorSubject(false);
    public allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
    public file: File = null;
    public imageData: string;
    public imageDirection: string = null;
    public loaderText: string = '...';
    public showLoader: boolean = false;
    public hooray: boolean = false;

    constructor(
        public panelRef: OverlayPanelRef<OpenMainImagePanelComponent>,
        public importTool: ImportToolService,
        public removeBackgroundTool: RemoveBackgroundToolService,
        public replaceToolService: ReplaceToolService,
        protected canvas: CanvasService,
        public state: CanvasStateService,
        private http: HttpClient,
    ) {}

    public removeBgAndHooray() {
        this.showLoader = true;
        this.loaderText = 'Фон удаляется...';

        this.removeBackgroundTool.executeRemoveBackground(this.imageData).then(image => {
            this.replaceMainImage(image);

            this.showLoader = false;
            this.loaderText = null;

            this.hooray = true;
        });
    }

    public replaceAndHooray() {
        this.showLoader = true;
        this.loaderText = 'Загружается изображение...';

        return this.http.post(`${env.BACKEND_BASE_URL}/api/v1/assets/`, {data_uri: this.imageData}).toPromise().then((result: AssetUploadPayload) => {
            this.replaceMainImage(result.image);

            this.showLoader = false;
            this.loaderText = null;
            this.hooray = true;
        });
    }

    public reset() {
        this.file = null;
    }

    public dropped(files: NgxFileDropEntry[]) {
        const droppedEntry: FileSystemEntry = files[0].fileEntry;
        
        if (droppedEntry.isFile) {
            const droppedFileEntry = droppedEntry as FileSystemFileEntry;

            droppedFileEntry.file((file: File) => {
                if (file.type.indexOf('image/') !== -1) {
                    this.file = file;

                    new UploadedFile(file).getData().then(result => {
                        this.imageData = result as string;
                    });
                }
            });
        };
    }

    private replaceMainImage(imageData: string) {
        const mainImage = this.canvas.getObjectById('main') as fabric.Image;

        if (mainImage === null) {
            this.canvas.openImage(imageData);
        } else {
            this.replaceToolService.replaceImage(mainImage, imageData)
        }
    }
}
