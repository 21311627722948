import {Injectable} from '@angular/core';
import { Image } from 'fabric/fabric-impl';
import {ObjectControlsService} from 'app/image-editor-ui/object-controls/object-controls.service';
import {CanvasService} from 'app/image-editor/canvas/canvas.service';
import {CanvasStateService} from 'app/image-editor/canvas/canvas-state.service';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';


interface AssetUploadPayload {
    image: string;
    data_uri: string;
    size?: number;
}

@Injectable()
export class ReplaceToolService {
    constructor(
        protected canvas: CanvasService,
        private objectControls: ObjectControlsService,
        public state: CanvasStateService,
        private http: HttpClient,
    ) {}

    public replaceImage(mainImage: fabric.Image, imageData: string): void {
        if (imageData.indexOf('data:image') !== -1) {
            this.canvas.state.contentLoadingState$.next({name: 'overlayImage', loading: true});

            this.http.post(`${env.BACKEND_BASE_URL}/api/v1/assets/`, {data_uri: imageData}).toPromise().then((result: AssetUploadPayload) => {
                this.executeReplace(mainImage, result.image);
    
                this.canvas.state.contentLoadingState$.next({name: 'overlayImage', loading: false});
            });
        } else {
            this.executeReplace(mainImage, imageData);
        }
    }

    private executeReplace(mainImage: fabric.Image, imageData: string): void {
        const initialWidth = mainImage.width,
              initialHeight = mainImage.height;
        
        mainImage.setSrc(imageData, () => {
            // use either main image or canvas dimensions as outer boundaries for scaling new image
            const maxWidth  = Math.min(initialWidth, this.state.original.width),
                  maxHeight = Math.min(initialHeight, this.state.original.height);

            // if image is wider or higher then the current canvas, we'll scale it down
            if (mainImage.width >= maxWidth || mainImage.height >= maxHeight) {

                // calc new image dimensions (main image height - 10% and width - 10%)
                const newWidth  = maxWidth - (0.1 * maxWidth),
                    newHeight = maxHeight - (0.1 * maxHeight),
                    scale     = 1 / (Math.min(newHeight / mainImage.getScaledHeight(), newWidth / mainImage.getScaledWidth()));

                // scale newly uploaded image to the above dimensions
                mainImage.scaleX = mainImage.scaleX * (1 / scale);
                mainImage.scaleY = mainImage.scaleY * (1 / scale);
            }

            this.canvas.render();
            this.objectControls.reposition();

            this.canvas.activeObject.select(mainImage);
        }, {
            crossOrigin: "anonymous",
        });
    }
}