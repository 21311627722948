export const presetIds = [
    'd3987eef-791a-462d-a899-3f947eb6fcbb',
    '004fd71d-7985-400a-a019-b707a3ec7ca5',
    'a6090529-252e-403a-a6a5-9498f1d94cbf',
    'd97a5ef1-36b7-4d77-a4ea-d6d0f7774605',
    '36f8b49f-74ec-4f09-9b25-6c9f61444b2a',
    '82e5e9b1-e835-48a9-9459-89c8b9d9f0ee',
    '9b09f73b-9bf0-4c43-ba7e-9d22ab4e039b',
    'f56627de-6d40-4fbb-a0d2-368110720bd5',
    '14a22bbb-d011-4086-a43b-b9d8f6f6745e',
    '62f2b384-c070-4577-b9cd-e9e32a4da374',
    '3e1d80d6-121a-424c-b7f7-25377d136cea',
    '4445ec11-5ff6-4cc0-a09b-4dfef23d364b',
    '64254761-a200-4791-afde-ee68d0c15896',
    'f6630124-2356-48da-a1c2-b13cb7ce01ad',
    '09c6c5cf-b609-4b09-8f98-7a9e31bb73f4',
    '8bb5914d-3cf2-488f-adc1-70d8f6824ef9',
    '0b0804ed-6b0f-4e7e-94bb-e642efade84a',
    '7976c188-35e8-4f3f-ae1f-b808965824a3',
    'ae99de5c-6606-404d-b5ab-19d1d4fde882',
    'b2f826c8-f628-41ca-a437-a077167aecd6',
    'b39c137e-a3fb-469f-a68d-89adc2c0831f',
    'b3f26928-acd2-4849-96da-2e758429a1af',
    'f1421fa7-5fb0-459d-be8f-ba8a568e2b40',
    '8f11a662-33d1-4905-8f79-83dd4ad77554',
    '72b46824-3724-4ab5-a9d6-7f225caf2ab6',
    '51942cac-dc43-4087-9c6b-6fe0239ab949',
    '4430f964-4287-44cf-8890-f20013f5830a',
    '32668545-d067-43ba-83d8-94845d454fa2',
    '117e6997-133a-442f-9bbd-fb399167f53b',
    '2d29e91e-10a4-4ce0-a381-8ec06fe9e9b7',
    '2f17aa53-fc1d-4cc8-a3c5-803fa9f15b9d',
    '32086f45-8291-4b68-b9db-d0fe4632d5d6',
    'b503f95e-ed82-400d-ba3d-b9033ee14fdf',
    '24bda4cf-fb5d-4b74-9d33-d313c569a3f5',
    'dc22c3a9-cfe9-421f-9fcf-e920c9b35610',
    'ef6798c5-f113-4291-a791-50667e84e12c',
    'feed1c54-785e-43f3-abb1-f3344b36309f',
    '4cb9f87e-4950-42e5-b18d-79f99c86a577',
    'de99e87b-3934-42af-b476-8314e417cd54',
    '8982a598-cc85-4aaf-a252-f832c59cb644',
    'fbfc3f9c-e6a4-4a1c-babd-eb777e264c5c',
    'e46142f9-eded-4fa5-8ced-23724610b90b',
    'cd945db2-eb2a-4fb1-a689-9651ee4e1e7a',
    'bf52027d-407e-4a57-becc-8d6f0378c60f',
    'ba213a94-612c-4c43-9b4c-065d1d758c87',
    'b2ba060a-582d-4fa3-bc7d-2a1e1525d512',
    'a08cb74a-a920-470d-a81a-74aa64a472b9',
    '4f0b8e4d-d280-4b87-9674-d49ebe6551b7',
    '47fed76c-1f84-4643-bf69-af30c3c8c6dc',
    '70eb2cb2-01ff-496e-a963-b3aec4671b12',
    '9eeb7535-f5f3-4180-af07-46fcd1c360e6',
    'f7a62fc1-3ce9-47a8-82bb-31fc2a0fbd0a',
    '7b700179-eb83-4ab8-bb9d-b78def1048e3',
    '348b0268-ec3f-4b2e-9c0a-53df7f70695e',
    'afbb627a-d1ef-4676-b196-0df5857ada06',
    '146a3473-917e-4b11-a1bf-635fc780c35f',
    'c1e5f99b-78f7-4721-aea8-d77e0ace8585',
    'edcc9fb8-5afd-4ad5-86ae-1536be513f37',
    '71ab42d2-0cee-4f24-87f2-efe8cc97cd5a',
    'd33a7bb2-0aa9-4a71-83dc-0efe9e5a231d',
    'd5d130a3-bbce-4c9a-ba25-45e73a4f1f51',
    'f7c43651-eebb-4368-a743-d76944efd732',
    '5fe689d9-d2df-412c-af98-3cd7b082635f',
];