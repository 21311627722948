import { Component, Input, ViewEncapsulation, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { PromoVideoOpenerService } from "app/image-editor-ui/popups/promo-video/promo-video-opener.service";

@Component({
    selector: 'promo-button',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './promo-button.component.html',
})
export class PromoButtonComponent {
    @Input() text: string;
    @Input() video: string;

    constructor(
        private sanitizer: DomSanitizer,
        private promoVideoOpenerService: PromoVideoOpenerService,
    ) {}

    openVideo(): void {
        const video = this.sanitizer.bypassSecurityTrustResourceUrl(this.sanitizer.sanitize(SecurityContext.URL, this.video))

        this.promoVideoOpenerService.open(video);
    }
}
