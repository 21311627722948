import {IGradientOptions} from 'fabric/fabric-impl';

export const gradientPresets: IGradientOptions[] = [
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 100,
            x2: 100,
            y2: 0,
        },
        colorStops: [
            {color: '#D53EA4', offset: '0'},
            {color: '#DE3675', offset: '0.5'},
            {color: '#4F52E4', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#B30185', offset: '0'},
            {color: '#FE347C', offset: '0.25'},
            {color: '#F382FF', offset: '0.75'},
            {color: '#F279F2', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 0,
        },
        colorStops: [
            {color: '#6B1182', offset: '0'},
            {color: '#C811AB', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#F8C421', offset: '0'},
            {color: '#FF7A00', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#9FE502', offset: '0'},
            {color: '#3DBB4E', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#74FBD6', offset: '0'},
            {color: '#6DABE3', offset: '0.5'},
            {color: '#D441F6', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#a8edea', offset: '0'},
            {color: '#fed6e3', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#abecd6', offset: '0'},
            {color: '#fbed96', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#13547a', offset: '0'},
            {color: '#80d0c7', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#ffecd2', offset: '0'},
            {color: '#fcb69f', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#f6d365', offset: '0'},
            {color: '#fda085', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#fdfbfb', offset: '0'},
            {color: '#ebedee', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#2f3944', offset: '0'},
            {color: '#414d5a', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#fff1eb', offset: '0'},
            {color: '#ace0f9', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#f3e6e9', offset: '0'},
            {color: '#dbd5ec', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#d3fbfb', offset: '0'},
            {color: '#fbdd97', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#b3b6eb', offset: '0'},
            {color: '#e98a98', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#4facfe', offset: '0'},
            {color: '#00f2fe', offset: '1'},
        ]
    },
    {
        type: 'linear',
        coords: {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        },
        colorStops: [
            {color: '#1267c2', offset: '0'},
            {color: '#0fbef5', offset: '1'},
        ]
    },
];
